<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <section class="grid-view">
      <b-card
        v-for="(application, i) in applications"
        :key="i"
        :title="application.title"
        class="ecommerce-card"
        img-alt="card img"
        img-top
        no-body
      >
        <b-card-body>
          <b-card-text>
            <b-card-title class="mb-1">
              <b-badge
                variant="primary"
                size="lg"
                class="mr-25"
              >
                Round {{ application.round_number }}
              </b-badge>
              {{ application.title }}
              <small v-if="application.status !== 'enabled'">
                <b-badge
                  class="text-capitalize"
                  small
                > Applications
                  {{ application.status }}
                </b-badge>
              </small>
            </b-card-title>
            <hr>
            <p
              class="h5"
            >Status:
              <b-badge
                pill
                :variant="application.status === 'enabled' ? 'success' : ''"
                class="text-capitalize"
                size="lg"
                v-text="application.status"
              />
            </p>
            <p
              class="h5"
            >Review Status:
              <b-badge
                pill
                :variant="application.review_status === 'Promoted' ? 'success' : ''"
                class="text-capitalize"
                size="lg"
                v-text="application.review_status"
              />
            </p>
          </b-card-text>
        </b-card-body>
        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-link
            class="btn btn-primary btn-cart"
            :href="`/mentor-portal/competitions/select/${$route.params.id}/${$route.params.pid}/round/${application.id}/${$route.params.sname}#Participants`"
          >
            <span>Open</span>
          </b-link>
        </div>
      </b-card>
    </section>
  </b-overlay>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BLink,
  BOverlay,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'
// import store from '@/store'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BBadge,
    BOverlay,
    BLink,
  },
  data() {
    return {
      selectStartup: {
        id: null,
        current_application_form_id: null,
        organization_id_id: null,
      },
      startup_id: [],
      applicants: [],
      applications: [],
      userOrgs: getUserData()
        .associatedOrgDetails
        .map(el => el.organization_id)
        .filter(n => n),
    }
  },
  apollo: {
    // applicants: {
    //   query() {
    //     return gql`
    //     {
    //      programs_applicantstable(where: {program_id: {_eq: ${this.$route.params.id}}}) {
    //         current_application_form_id
    //         organization_id_id
    //         id
    //         users_organizationtable {
    //           title
    //         }
    //         users_customuser {
    //           full_name
    //         }
    //       }
    //     }`
    //   },
    //   update: data => data.programs_applicantstable,
    // },
    applications: {
      query() {
        return gql`
        query Rounds{
          programs_applicationformstable(where: {program_id: {_eq: ${this.$route.params.id}}, status: {_neq: "draft"}}, order_by: {id: desc}) {
            id
            title
            status
            review_status
          }
        }`
      },
      update: data => data.programs_applicationformstable,
    },
    startup_id: {
      query() {
        return gql`
        {
          programs_applicantstable(where: {id: {_eq: ${this.$route.params.aid}}}) {
            organization_id_id
          }
        }`
      },
      update: data => data.programs_applicantstable,
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
