<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-overlay
          rounded="sm"
          spinner-variant="primary"
        >
          <b-card
            class="text-center"
          >
            <b-card-header
              class="justify-content-start p-0 pb-1 mb-1 border-bottom"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <h3 class="mb-0 ml-2 d-flex justify-space-between">
                <span>Manage {{ $route.params.sname }}</span>
              </h3>
            </b-card-header>
            <b-tabs
              v-model="activeTab"
              active
              nav-class="mb-3"
              pills
              class="pb-2"
              @input="updateHash"
            >
              <b-tab
                id="roundsid"
                active
                href="Rounds"
                lazy
                title="Rounds"
              >
                <b-card
                  class="text-left"
                  title="Rounds"
                >
                  <rounds-manager />
                </b-card>
              </b-tab>
              <b-tab
                href="Participants"
                lazy
                title="Participants"
              >
                <b-card
                  class="text-left"
                  title="Participants"
                >
                  <startup-progress />
                </b-card>
              </b-tab>
              <b-tab
                title-item-class="calendarbtn"
                class="text-left"
                href="Activities"
                lazy
                title="Calendar"
              >
                <!-- <scheduler
                  v-if="!$apollo.loading"
                  :begin_date="program.begin_date"
                /> -->
              </b-tab>
              <b-tab
                class="text-left"
                href="Modules"
                lazy
                title="Modules"
              >
                <module-manager />
              </b-tab>
              <b-tab
                href="Workshops"
                lazy
                title="Workshops"
              >
                <b-card
                  class="text-left"
                  title="Manage Workshop"
                >
                  <workshop-manager />
                </b-card>
              </b-tab>
              <b-tab
                href="MasterClass"
                lazy
                title="Masterclass"
              >
                <b-card
                  class="text-left"
                  title="Manage Masterclass"
                >
                  <master-class-manager />
                </b-card>
              </b-tab>
              <b-tab
                class="text-left"
                href="E-Meet"
                lazy
                title="Sessions"
              >
                <b-card
                  class="text-left"
                  title="Meeting Manager"
                >
                  <!-- <emeet-manager /> -->
                </b-card>
              </b-tab>
              <b-tab
                v-if="$route.params.sid"
                href="Exercises"
                lazy
                title="Exercises"
              >
                <b-card
                  class="text-left"
                  title="Exercise Manager"
                >
                  <review-manager />
                </b-card>
              </b-tab>
              <b-tab
                v-if="$route.params.sid"
                href="Milestones"
                lazy
                title="Milestones"
              >
                <b-card
                  class="text-left"
                  title="Milestone Manager"
                >
                  <milestone-manager />
                </b-card>
              </b-tab>
              <b-tab
                href="KnowledgeBase"
                lazy
                title="Resources"
              >
                <b-card
                  class="text-left"
                  title="Resources"
                >
                  <!-- <knowledge-base /> -->
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'
// import Ripple from 'vue-ripple-directive'
import gql from 'graphql-tag'
// import Scheduler from './Scheduler.vue'
import RoundsManager from './RoundsManager.vue'
import StartupProgress from './StartupProgress.vue'
import ReviewManager from './ReviewManager.vue'
import MilestoneManager from './Milestone.vue'
// import EmeetManager from './EmeetManager.vue'
import WorkshopManager from './WorkshopManager.vue'
import MasterClassManager from './MasterClassManager.vue'
// import KnowledgeBase from './Library.vue'
import ModuleManager from './ModuleManager.vue'

export default {
  components: {
    // EmeetManager,
    WorkshopManager,
    ModuleManager,
    MasterClassManager,
    ReviewManager,
    RoundsManager,
    StartupProgress,
    MilestoneManager,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BTab,
    BTabs,
    BButton,
    // Scheduler,
    // KnowledgeBase,
  },
  // directives: {
  //   Ripple,
  // },
  data() {
    return {
      program: '',
      activeTab: 0,
      tabs: ['#Rounds',
        '#Participants',
        '#Activities',
        '#Modules',
        '#Workshops',
        '#MasterClass',
        '#Sessions',
        '#Exercises',
        '#Resources',
        '#Services',
        '#Feedback',
        '#Requests'],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
  apollo: {
    program: {
      query() {
        return gql`
        {
        programs_basicinfo_by_pk(id: "${this.$route.params.pid}"){
          id
          title
          begin_date
        }
      }`
      },
      update: data => data.programs_basicinfo_by_pk,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
.calendarbtn{
  position: absolute;
  right: 20px;
  top: 20px;
  border: solid 1px #7367F0;
  border-radius: 8px;
}
</style>
